<template>
  <div id="app">
    <div class="vdale_header">
      <h1>Welcome :)</h1>
    </div>
    <br/>

    <h1>In the News:</h1>

    <br/>
    <img class="image vdale_logo" alt="Villagedale Community Association" src="./assets/2025_02_20_HomeHardware_Screenshot.png">
    <img class="image vdale_logo" alt="Villagedale Community Association" src="./assets/2025_02_20_HomeHardwarePhotoTony.jpg">
    <br/>
    <br/>
    <br/>
    <a href="https://www.cbc.ca/player/play/video/9.6596005" target="_blank">
      <img class="image vdale_logo" alt="Villagedale Community Association" src="./assets/2025_01_03_CBC_video.png">
    </a>
    <p>
      <a href="https://www.cbc.ca/player/play/video/9.6596005" target="_blank">View the video here</a>
    </p>
    <p style="font-size: 1.6em;">
      "A group of volunteers in Villagedale, N.S., are ensuring people from all walks of life have food on their tables amid growing food insecurity. The Villagedale Community Association opened a small food pantry two years ago, but it has since grown to provide food boxes using donated goods from local grocery stores."<BR/>
    </p>
    <p>
      <a href="https://www.cbc.ca/player/play/video/9.6596005" target="_blank">View the video here</a>
    </p>

    <br/>
    <img class="image vdale_logo" alt="Villagedale Community Association" src="./assets/2024_12_09_saltwire.jpg">
    <p style="font-size: 1.6em;">
      "The spirit of the season is alive and well on Christmas Lane in Coffinscroft, Shelburne County, this holiday season.<BR/>
There's giving and receiving, joy and merriment, and opportunities to create lasting memories with loved ones.<BR/>
Christmas Lane is located on the Old Town Road in Coffinscroft, found along the Villagedale Road in the Municipality of Barrington."<BR/>
      <a href="https://www.saltwire.com/nova-scotia/tri-county-vangaurd/christmas-lane-in-shelburne-county-epitomizes-the-season-with-merriment-and-giving" target="_blank">Read SALTWIRE here</a>
    </p>


    <img class="image vdale_logo" alt="Villagedale Community  Association" src="./assets/2024_12_05_cbc_mainstreet_vca.png">
    <p style="font-size: 1.6em;">
      <b>CBC Mainstreet NS with Jeff Douglas - Make the Season Kind</b><br/>
Dec. 5, 2024<br/>
Mainstreet's live broadcast from Stardust Bar & Kitchen begins with Jenn Grant live on stage, then some sound from Alex Guye's visit to the Municipality of Barrington, and an interview with Kelly Currie from Feed Nova Scotia.
<BR/>
(listen at the 17:45 minute mark)<br/>
      <a href="https://www.cbc.ca/listen/live-radio/1-37-mainstreet-ns/clip/16114003-make-the-season-kind-jenn-grant-villagedale-community" target="_blank">LISTEN to the interview here</a>
    </p>

    


    <br/>
    <br/>


    <img class="image vdale_logo" alt="Villagedale Community  Association" src="./assets/vca_logo_dark.png">


    <br/>
    <br/>
    <br/>
    <br/>
    <br/>

    <h1>We're here to help.</h1>

    <br/>

    <h3>For support or to contribute, please contact <a href="mailto:support@villagedale.ca">support@villagedale.ca</a></h3>
    <h3>For the most up-to-date information on food security, visit us on Facebook<br/><a href="https://www.facebook.com/groups/villagedale" target="new">https://www.facebook.com/groups/villagedale</a></h3>
    <!--<p><a href="/media/December-2023-Monthly-Calendar.pdf" target="new">Download our December 2023 Food Challenge Poster here</a></p>-->

    <br/>

    <div class="info">

      <h3>In the News:</h3>
      
      <p><a href="https://www.cbc.ca/player/play/video/9.4223955" target="new">Shelburne County food bank fears running out of money</a><br/>
      CBC Nova Scotia News, 2024-05-10, Duration 3:15<br/>
      It's been a very busy time at the food bank ever since the wildfires ravaged the area last spring. The number of people it is supporting is on the rise. Paul Palmeter has the story.</p>

    </div>

    <div class="info">

      <p><b>Welcome to the Villagedale Community Association!</b></p>
      <p>
        We are a registered, non-profit society based in Nova Scotia, dedicated to fostering a diverse, inclusive, and supportive community that upholds the dignity of all. Our Food Security initiative is designed to ensure equitable access to nutritious food for 200 to 300 individuals each week. This initiative is powered by strong partnerships with local businesses like Sobeys, NoFrills, Needs, Madd Batter Bakery, through Second Harvest Food Rescues, as well as through the generosity of our neighbors and a range of grants.
      </p>
      <p>
        Our collaborative efforts create a sustainable network that addresses immediate food needs and promotes long-term community resilience. By bridging local producers, volunteers, and donors, we strive to eliminate barriers and establish a compassionate, welcoming environment for everyone.
      </p>
      <p>
        Through partnerships and unwavering commitment to diversity and inclusion, we aim to build a better future by empowering individuals and families with the resources and support they need. Together, we can ensure that no one in our community goes without the essentials, making Villagedale and area a stronger, more compassionate place.
      </p>
    </div>


    <div class="donations">
      <div class="donations-options">
        <h2>Make a one-time contribution to Food Security:</h2>
        <div id="donate-button-container">
          <div id="donate-button"></div>
        </div>
        <br/>
        <br/>
        <h2>Become a $5 Monthly Contributor:</h2>
        <div>
          <div id="paypal-button-container-P-6FN68383JW4009302MPSZTPA"></div>
        </div>
        <br/>
        <h2>Become a $10 Monthly Contributor:</h2>
        <div>
          <div id="paypal-button-container-P-91J85934Y1818935AMQYKNNA"></div>
        </div>
        <br/>
        <h2>Become a $20 Monthly Contributor:</h2>
        <div>
          <div id="paypal-button-container-P-8YY58841KH8820210MQYKOVQ"></div>
        </div>
      </div>
    </div>
    <br/>
    <br/>
    <p>
      <a href="/media/VCA_Food_Security_Volunteer_Agreement.pdf" target="new">Volunteer Agreement</a> | 
      <a href="/media/VCA_Food_Security_Program_Survey.pdf" target="new">Food Security Survey</a> | 
      <a href="/media/VCA_Food_Security_Initiative_Privacy_Policy.pdf" target="new">Privacy Policy</a> | 
      <a href="/media/VCA_Diversity_and_Inclusion_Policy.pdf" target="new">Diversity and Inclusion Policy</a> |
      <a href="/media/2023_AGM_Financials.zip">2023 Financial Reports</a> & 
      <a href="/media/2024_AGM_Financials.zip">2024</a>
    </p>
    <div class="info">
      <p>
        On a volunteer and non-profit basis the objective/s of the society is/are:
        <ul>
          <li>To relieve poverty by providing food and other basic necessities of life to needy individuals and families in Nova Scotia.</li>
          <li>To undertake activities ancillary and incidental to the attainment of the above charitable purpose.</li>
        </ul>
      </p>
      <p><b>Board of Directors</b>: Carla Nickerson, Jeff Nickerson, Chelsea Turner, Meredith Symonds, Jamie Symonds, Cameron Albright, Arley Goreham, Yetta Hirtle, Jacob Nickerson</p>
      <P><b>Villagedale Community Association</b> is a registered society in the Province of Nova Scotia in good standing. Registration date: Nov 1, 2022. Registration number: 4466832.  Registered Canadian Charity (July 9, 2024).</P>
    </div>
    <br/>





    <br/>
    <div class="vdale_footer">
      <p style="margin: auto;">&copy; 2024 Villagedale Community Association<br/>Website by <a href="https://topsailsoftware.ca" target="blank" style="color: white;">Topsail Software Inc</a></p>
      <p style="margin: auto; font-size: 0.6em;">Last updated: 10/06/2024</p>
    </div>
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: white;
  background-color: black;
  margin-top: 60px;
}
 
a{ 
  color: white;
}
@media (min-width:801px)  { 
  /* tablet, landscape iPad, lo-res laptops ands desktops */ 
  .donatoins {
    width: 50%;
  }
}
@media (min-width:1025px) { 
  /* big landscape tablets, laptops, and desktops */ 
  .donatoins {
    width: 50%;
  }
}
@media (min-width:1281px) { /* hi-res laptops and desktops */ 
  .donatoins {
    width: 50%;
  }
}
.donations {
  width: 100%;
}
.donations-options {
  width: 50%;
  margin: auto;
}
.info {
  padding: 20px;
  width: 80%;
  margin: auto;
  border-radius: 25px;
  font-size: 1.3em;
}
.vdale_footer {
  bottom: 0px; 
  width: 100%; 
  text-align: center; 
  color: white; 
  background-color: black; 
  padding-top: 10px; 
  padding-bottom: 10px;
}
.vdale_header {
  position: absolute; 
  top: 0px; 
  width: 100%; 
  background-color: black; 
  margin: 0px; 
  padding: 0px; 
  color: white;
}
.vdale_logo {
  min-width: 300px; 
  width: 60%;  
}
</style>